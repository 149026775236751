.Snippet, .SnippetWithBasket, .basketButtonContainer, .SnippetLink, .SnippetLinkNoPrice{
    display: grid;
    place-items: center center;
}

.Snippet, .SnippetWithBasket {
    width: 95%;
    height: 35vh;
    border: solid 1px var(--main-txt);
    border-radius: 2.5px;
    grid-column: span 2;
    margin-top: 1.5vh;
}

.SnippetWithBasket {
    grid: 8fr 2fr / 1fr;
    height: 100%;
}

.SnippetWithBasket img {
    width: 15vh;
    height: 15vh;
    border-radius: 2.5px;
}

.Snippet img {
    width: 15vh;
    height: 15vh;
    border-radius: 2.5px;
}

.basketButtonContainer {
    height: 100%;
    width: 100%;
    grid-auto-flow: column;
    font-size: 1.2rem;
    border-top: solid 1px var(--main-txt);
    cursor: pointer;
}

.inBasketButtonContainer {
    background: var(--main-txt);
    color: var(--main-bg);
}

.SnippetLink {
    width: 100%;
    height: 100%;
    grid: 5fr 3.5fr 1.5fr / 1fr;
}

.SnippetLinkNoPrice {
    width: 100%;
    height: 100%;
    grid: 6fr 4fr / 1fr;
}

.title {
    display: grid;
    place-items: center center;
    height: 100%;
    width: 100%;
    font-size: calc(100% + 0.75vw);
}

.price {
    border-top: solid 1px var(--main-txt);
    display: grid;
    place-items: center center;
    width: 100%;
    height: 100%;
    font-size: 0.90rem;
    place-self: center end;
    width: 50%;
    border-left: solid 1px var(--main-txt);
    border-top-left-radius: 20px;
    padding-left: 5px;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {

    .Snippet{
        height: 22vh;
    }

    .SnippetWithBasket img {
        grid-row: 1 / 3;
        width: 100%;
        height: 23.5vh;   
    }
    
    .Snippet img {
        grid-row: 1 / 3;
        width: 100%;
        height: 21.5vh;
    }
    
    .SnippetLink, .SnippetLinkNoPrice {
        grid: 1fr / 2.5fr 7.5fr;
    }
    
    .title {
        grid-row: 1 / 2;
        height: 100%;
        width: 100%;
        display: grid;
        place-items: center center;
    }

    .price {
        width: 35%;
        font-size: 0.75rem;
    }
}

@media only screen and (min-width : 321px) and (max-width : 1023px) and (orientation : landscape) {

    .Snippet, .SnippetWithBasket {
        height: 35vh;
    }

    .SnippetWithBasket img {
        grid-row: 1 / 3;
        width: 24vh;
        height: 27vh;   
    }
    
    .Snippet img {
        grid-row: 1 / 3;
        width: 100%;
        height: 34vh;
    }
    
    .SnippetLink, .SnippetLinkNoPrice {
        grid: 1fr / 2.5fr 7.5fr;
    }
    
    .title {
        grid-row: 1 / 2;
        height: 100%;
        width: 100%;
        display: grid;
        place-items: center center;
    }

    .price {
        width: 30%;
        font-size: 0.75rem;
    }
}

@media only screen and (min-width : 820px) and (orientation : portrait) {

    .Snippet, .SnippetWithBasket {
        height: 36vh;
        width: 85%;
    }

    .Snippet {
        grid: 36vh / 1fr;
    }

    .SnippetWithBasket {
        grid: 30vh 6vh / 1fr;
    }

    .SnippetWithBasket img {
        grid-row: 1 / 2;
        width: 100%;
        height: 25vh;   
    }
    
    .Snippet img {
        grid-row: 1 / 2;
        width: 97.5%;
        height: 27.5vh;
    }
    
    .title {
        grid-row: 2 / 3;
        font-size: 2.5vw;
    }
}