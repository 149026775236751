.GiftVouchersPending {
    grid-area: main;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: start center;
    background: var(--main-bg);
    grid-template-rows: 5vh;
}

.GiftVoucherHeader, .voucherType, .selectedVoucherType, .allVouchersContainer, .controlsContainer {
    display: grid;
    place-items: center center;
}

.GiftVoucherHeader {
    grid-auto-flow: column;
    width: 100%;
}

.voucherType, .selectedVoucherType {
    background: var(--second-bg);
    width: 97.5%;
    height: 100%;
}

.selectedVoucherType {
    background: var(--main-txt);
    color: var(--second-bg);
}

.allVouchersContainer {
    width: 100%;
}

.customerVoucherContainer {
    width: 97.5%;
    display: grid;
    place-items: center start;
    grid-template-columns: 3fr 7fr;
    background: white;
    margin-top: 2.5vh;
    padding: 5px;
    height: 60vh;
    text-align: left;
}

.customerVoucherContainer p {
    margin-top: 5px;
}

.controlsContainer {
    grid-column: 1 / 3;
    width:95%;
    grid-template-columns: 1fr;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
    
    .allVouchersContainer {
        grid-template-columns: 1fr 1fr;
    }

    .customerVoucherContainer {
        height: 30vh;
        grid-template-columns: 1fr 5fr 4fr;
        overflow-y: auto;
    }

    .controlsContainer {
        grid-column: 3 / 4;
        grid-row: 1 / 10;
        width:40%;
        grid-template-columns: 1fr;
    }

    .voucherType, .selectedVoucherType {
        padding: 1vh 0;
        font-size: 1.5rem;
    }
}

@media only screen and (min-width : 321px) and (max-width : 1023px) and (orientation : landscape) {
    .allVouchersContainer {
        grid-template-columns: 1fr;
    }

    .customerVoucherContainer {
        height: auto;
        grid-template-columns: 1fr 5fr 4fr;
    }

    .controlsContainer {
        grid-column: 3 / 4;
        grid-row: 1 / 10;
        width:40%;
        grid-template-columns: 1fr;
    }

    .voucherType, .selectedVoucherType {
        margin-top: 2.5vh;
        font-size: 1.2rem;
    }
}