.Basket {
    position: fixed;
    width: 95%;
    height: 80vh;
    left: 2.5%;
    top: 10vh;
    background: var(--main-bg);
    z-index: 9;
    box-sizing: border-box;
    transition: transform 0.75s ease-out;
    display: grid;
    place-items: start center;
    grid-template-rows: 1fr 7fr 0.5fr 1.5fr;
    border-radius: 5px;
}

.Show {
    transform: translateY(0);
}

.Hide {
    transform: translateY(-150%);
}

.basketContainer {
    display: grid;
    place-items: start center;
    width: 97.5%;
    height: 100%;
    overflow-y: scroll;
    border-top: solid 1px var(--main-txt);
    padding: 2vh 0;
}

.noItemsInBasket {
    place-self: center center;
    font-weight: 300;
}
.basketControlsContainer, .totalPrice, .bookTreatmentsButton, .calanderContainer, .calanderContainer h4, .calanderImagecontainer, .completed, .logo {
    display: grid;
    place-items: center center;
}
.basketControlsContainer {
    height: 100%;
    width: 100%;
    grid-auto-flow: column;
}

.totalPrice {
    width: 95%;
    height: 100%;
    font-size: 1.5rem;
    border-top: solid 1px var(--main-txt);
}

.bookTreatmentsButton {
    font-size: 0.9rem;
    padding: 0;
    height: 65%;
    margin: 0;
}

.calanderContainer {
    background: white;
    width: 90%;
    height: 100%;
}

.calanderContainer h4 {
    grid-auto-flow: column;
    width: 100%;
}

.calanderImagecontainer {
    grid-row-gap: 20px;
}

.calanderImagecontainer svg {
    font-size: 2.5rem;
}

.completed {
    grid-row: 1 / 5;
    place-self: center center;
    width: 90%;
    height: 90%;
    grid-template-rows: 2fr 6fr 2fr;
}

.logo {
    width: 70%;
}

.customerMessageContainer {
    height: 80%;
    display: grid;
    place-items: center center;
}

.atLeast16 {
    display: grid;
    place-items: center center;
    grid-template-columns: 2fr 10fr;
    
}

.atLeast16 p {
    margin-top: 10px;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
    .Basket {
        height: 90vh;
        top: 5vh;
        width: 75%;
        left: 12.5%;
    }
}

@media only screen and (min-width : 321px) and (max-width : 1023px) and (orientation : landscape) {
    .Basket {
        height: 90vh;
        top: 5vh;
        width: 75%;
        left: 12.5%;
    }
}