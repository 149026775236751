.TrainingCourses {
    place-self: center start;
    grid-area: main;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 3vh auto;
    grid-row-gap: 1vh;
    place-items: start center;
    padding: 2.5vh 0;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
    .TrainingCourses {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}