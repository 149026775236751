.SingleTrainingCourse {
    grid-area: main;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center center;
}

.courseImage {
    display: grid;
    place-items: center center;
    width: 40vh;
    height: 40vh;
    margin-bottom: 1vh;
    border-radius: 2.5px;
}

.stat, .curriculumContainer {
    margin-top: 2vh;
    width: 75%;
    display: grid;
    place-items: center start;
    grid-template-columns: 3.5fr 6.5fr;
}

.curriculumContainer {
    grid: 1fr auto / 1fr;
    border-top: solid 2px var(--main-txt);
    border-bottom: solid 2px var(--main-txt);
    padding: 1vh 0;
    place-items: center center;
    text-align: left;
}

.curriculumContainer h3 {
    place-self: center start;
}

.curriculum {
    width: 100%;
    display: grid;
    place-items: center start;
    padding-left: 10vw;
}

.noCurriculumContainer {
    margin: 5vh 0;
    font-size: 1.75rem;
    width: 100%;
}

.curriculumContainer a, .curriculumContainer a div {
    width: 100%;
    display: grid;
    place-items: center center;
    grid-auto-flow: column;
}

.floatButton {
    position: sticky;
    top: 12.75vh;
    padding: 1vh;
    width: 95%;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {

    .SingleTrainingCourse {
        grid-template-columns: 1fr 1fr;
    }

    .floatButton {
        top: 20.25vh;
        margin-top: 2.5vh;
    }

    .courseImage {
        position: sticky;
        top: 20.25vh;
        grid-row: 2 / 100;
        width: 60vh;
        height: 60vh;
        place-self: start center;
    }
}

@media only screen and (min-width : 321px) and (max-width : 1023px) and (orientation : landscape) {
    .SingleTrainingCourse {
        grid-template-columns: 1fr 1fr;
    }

    .floatButton {
        top: 13vh;
    }

    .courseImage {
        position: sticky;
        margin-top: 2.5vh;
        top: 13vh;
        grid-row: 2 / 9;
        width: 60vh;
        height: 60vh;
        place-self: start center;
    }
}