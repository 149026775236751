.ErrorPopup {
    position: fixed;
    height: 50%;
    width: 75%;
    left:12.5%;
    top: 25%;
    z-index: 10;
    background: var(--main-bg);
    color: var(--error-txt);
    display: grid;
    place-items: center center;
    border-radius: 15px;
    padding: 0 10px;
}

.title {
    font-size: 1.5rem;
    border: solid 1px var(--error-txt);
    padding: 5px;
    border-radius: 15px;
}

.closeButton {
    padding: 0;
    box-shadow: none;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
    .ErrorPopup {
        top: 10%;
        height: 80%;
        width: 40%;
        left: 30%;
        font-size: inherit;
    }

    .title {
        font-size: 2.5rem;
    }
}