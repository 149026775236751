.confirmDeleteContainer {
    top: 25%;
    left: 10%;
    height: 50%;
    width: 80%;
    position: fixed;
    z-index: 10;
    display: grid;
    place-items: end center;
}

.ConfirmDelete {
    background: var(--main-bg);
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center center;
    z-index: 11;
    border-radius: 3.75px;
}

.Logo {
    width: 30%;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
    .confirmDeleteContainer {
        top: 25%;
        left: 25%;
        height: 50%;
        width: 50%;
    }
}

@media only screen and (min-width : 321px) and (max-width : 1023px) and (orientation : landscape) {
    .confirmDeleteContainer {
        margin-top: 10vh;
        top: 5vh;
        left: 25%;
        height: 80vh;
        width: 50%;
    }
}