.NewSalonTreatment {
    grid-area: main;
    padding: 1vh 0 2.5vh 0;
    width: 100%;
    display: grid;
    place-items: center center;
    background: var(--main-bg);
    z-index: 2;
}

.multiPriceOverallContainer {
    display: grid;
    place-items: center center;
    width: 90%;
}

.multiPriceContainer {
    display: grid;
    grid-template-columns: 7fr 2fr 1fr;
    place-items: center center;
    position: relative;
}

.multiPriceContainer p {
    position: absolute;
    top: 0;
    left: 20px;
}

.priceType {
    width: 100%;
    display: grid;
    place-items: center center;
}

.priceTypeButton {
    width:90%;
    margin-top: 5px;
    text-align: right;
}

.newPriceButton {
    margin-top: 5px;
    display: grid;
    place-items: center center;
    width: 95%;
    grid-auto-flow: column;
}

.canHaveNailArt {
    display: grid;
    place-items: center center;
    grid-template-columns: 2fr 10fr;
}

@media only screen and (min-width : 321px) and (max-width : 1023px) and (orientation : landscape) {
    .NewSalonTreatment {
        grid-area: main;
    }
}