.GoBack {
    place-self: start start;
    height: 3vh;
    width: 25%;
    margin: 0vh 0 0.5vh 1vw;
    display: grid;
    place-items: center center;
    grid-auto-flow: column;
    color: var(--menu-txt);
    background: var(--menu-bg);
    border-radius: 3.75px;
    font-weight: 700;
    cursor: pointer;
    grid-column: 1 / -1;
}

@media(min-width: 1199px){
    .GoBack {
        width: 12.5%;
    }
}

@media(min-width: 1999px){
    .GoBack {
        width: 10%;
    }
}