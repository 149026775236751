.basketMain {
    display: grid;
    place-items: start center;
    width: 97.5%;
    height: 100%;
}

.basketContainer {
    display: grid;
    place-items: start center;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    border-top: solid 1px var(--main-txt);
    padding: 2vh 0;
}

.noItemsInBasket {
    place-self: center center;
    font-weight: 300;
}
.basketControlsContainer, .calanderContainer, .calanderContainer h4, .calanderImagecontainer, .completed, .logo {
    display: grid;
    place-items: center center;
}

.customerMessageContainer {
    height: 80%;
    display: grid;
    place-items: center center;
}