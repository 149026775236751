:root {
  --main-bg: whitesmoke;
  --second-bg: #ece0d1;
  --second-bg-trans: rgb(236, 224, 209, 0.65);
  --main-txt: #967259;
  --err-bg: rgba(97, 55, 55, 0.25);
  --scs-bg: rgb(0, 128, 0, 0.65);
  --error-txt: #e81c20;
  --yes-txt: green;
  --amber-txt: orange;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  text-align: center;
  background: var(--main-bg);
  color: var(--main-txt);
}

body {
  height: 100%;
  font-family: 'Poiret One', cursive;
}

a {
  text-decoration: none;
  font-family: 'Poiret One', cursive;
  color: var(--main-txt);
  cursor: pointer;
}

p {
  font-family: 'Nunito', sans-serif;
}

/* Main Button */
.customButton {
  font-family: 'Nunito', sans-serif;
  width: 85%;
  display: grid;
  place-items: center center;
  grid-auto-flow: column;
  background: var(--main-txt);
  border: 0 solid #e5e7eb;
  border-radius: 2.5px;
  box-sizing: border-box;
  color: var(--main-bg);
  cursor: pointer;
  font-size: 100%;
  font-weight: 700;
  line-height: 24px;
  transition: all .1s cubic-bezier(.4, 0, .2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  box-shadow: -6px 8px 10px rgba(81,41,10,0.1),0px 2px 2px rgba(81,41,10,0.2);
  padding: 5px 0;
  margin-top: 15px;
}

.customButton:active {
  background-color: #f3f4f6;
  box-shadow: -1px 2px 5px rgba(81,41,10,0.15),0px 1px 1px rgba(81,41,10,0.15);
  transform: translateY(0.125rem);
}

.customButton:focus {
  box-shadow: rgba(72, 35, 7, .46) 0 0 0 4px, -6px 4px 6px rgba(81,41,10,0.1), 0px 2px 2px rgba(81,41,10,0.2);
}

/* Input styling */

input, textarea, select {
  text-align: center;
  width: 80%;
  padding: 10px 0;
  font-size: 1.25rem;
  border: rgba(0, 0, 0, 0.27) solid 1px;
  border-radius: 3.75px;
  margin-top: 10px;
}

textarea {
  height: 15vh;
  resize: none;
}

input:focus, textarea:focus, select:focus {
  outline-color: var(--second-bg);
}

/* Error and success message styling */
.error, .success {
  color: var(--error-txt);
  margin: 5px 0;
  padding: 10px 5px;
  width: 100%;
  display: grid;
  place-items: center center;
  border-radius: 5px;
}

.success {
  color: var(--main-bg);
  background: var(--scs-bg);
  grid-column: 1 / -1;
}

.delete {
  color: var(--error-txt);
  grid-column: 1 / -1;
}

.edit {
  color: orange;
}

.admin-buttons {
  display: grid;
  place-items: center center;
  grid-auto-flow: column;
  width: 100%;
  font-size: 2.5rem;
}

.image-details-container {
  width: 100%;
  display: grid;
  place-items: center center;
  grid-auto-flow: column;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  z-index: 11;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: var(--second-bg);
}

.selectable {
  cursor: pointer;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
  html {
    font-size: 1.2rem;
  }

  .customButton {
    width: 40%;
  }

  .customButton:hover {
    background: var(--second-bg);
    color: var(--main-txt);
  }

  .largeNavItem {
    padding: 7.5% 2.5%;
    border-radius: 5px;
  }

  .largeNavItem:hover {
    background: var(--main-txt);
    color: var(--second-bg);
  }

  .mainAdminSection {
    margin-top: 25vh;
  }
}

@media only screen and (min-width : 834px) and (max-width : 1024px) and (orientation : portrait) {
  html {
    font-size: 2.1rem;
  }

  .customButton {
    width: 40%;
  }

  input, textarea, select {
    text-align: center;
    width: 80%;
    padding: 10px 0;
    font-size: 1.8rem;
    border: none;
    border-radius: 3.75px;
    box-shadow: 0px 1px 1px black;
    margin-top: 10px;
    height: 75%;
  }
  
  textarea {
    height: 15vh;
    resize: none;
  } 
}