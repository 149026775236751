.Footer {
    grid-area: footer;
    background: var(--second-bg);
    display: grid;
    place-items: center center;
    width: 100%;
}

.policiesContainer {
    display: grid;
    place-items: center center;
    width: 100%;
    grid-auto-flow: column;
}

.policies {
    display: grid;
    place-items: center center;
}

.policies a {
    margin-top: 25px;
}