.EditSalonTreatment {
    grid-area: main;
    padding: 1vh 0 2.5vh 0;
    width: 100%;
    display: grid;
    place-items: center center;
    background: var(--main-bg);
    z-index: 2;
}

.currentImageContainer {
    margin-top: 2.5vh;
    width: 80%;
    position: relative;
    border: 1px solid var(--main-txt);
}

.noImageContainer {
    width: 100%;
}

.currentImage {
    margin: 2.5vh 0;
    max-height: 30vh;
    border-radius: 2.5px;
}

.deleteImageButton {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 2rem;
}