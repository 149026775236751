.loginContainer {
    place-self: center center;
    grid-area: header/latest/main;
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center center;
    z-index: 4;
    background: var(--main-bg);
}

.Login {
    background: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 2.5px;
    display: grid;
    place-items: center center;
    height: 50%;
    width: 90%;
}

.Login a {
    text-decoration: none;
    color: var(--menu-bg);
    font-weight: bold;
}

.Login button {
    padding: 0;
    width: 50%;
    height: 45%;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {

    .loginContainer {
        grid-area: main;
        height: 50vh;
        width: 40vw;
        z-index: 0;
        margin: 15vh 0;
    }

    .Login {
        height: 100%;
    }

    .Login, .Login input {
        font-size: 0.9rem;
    }

    .Login input {
        width: 50%;
    }

    .Login button {
        padding: 0;
        width: 30%;
        height: 30%;
    }
}

@media only screen and (min-width : 321px) and (max-width : 1023px) and (orientation : landscape) {
    
    .loginContainer {
        grid-area: main;
        height: 60vh;
        width: 50vw;
        z-index: 0;
        margin: 13.75vh 0;
    }

    .Login {
        height: 100%;
    }

    .Login, .Login input {
        font-size: 1.15rem;
    }

    .Login button {
        padding: 0;
        width: 50%;
        height: 75%;
    }
}