.PrivacyPolicy, .url {
    position: fixed;
    z-index: 11;
    background: var(--main-bg);
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 5px;
    display: grid;
    place-items: center center;
    padding-bottom: 25%;
}

.PrivacyPolicy h4, .url h4 {
    margin: 5vh 0;
    text-decoration: underline;
}

.url {
    position: static;
    z-index: 0;
}

.logo {
    width: 25%;
    z-index: 0;
    overflow-y: none;
}

.fixedButton {
    position: fixed;
    z-index: 12;
    height: 10%;
    top: 85%;
    bottom: 5%;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
    .PrivacyPolicy {
        padding: 5% 20%;
    }
}