.DrawerToggle {
    height: 100%;
    display: grid;
    place-items: center end;
    padding: 0 2.5%;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
    .DrawerToggle {
        display: none;
    }
}

@media only screen and (min-width : 321px) and (max-width : 1023px) and (orientation : landscape) {
    .DrawerToggle {
        place-items: center center;
        padding: 0;
    }
}