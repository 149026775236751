.SingleSalonTreatment {
    grid-area: main;
    padding: 1vh 0 2.5vh 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center center;
}

.treatmentImage {
    margin-top: 2.5vh;
    width: 240px;
    height: 240px;
    border-radius: 2.5px;
}

.title {
    font-size: 2rem;
}

.statsContainer {
    margin-top: 2.5vh;
    font-size: 1.5rem;
    display: grid;
    grid: 1fr 1fr / 1fr 1fr;
    width: 100%;
}

.stat {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 8fr;
    place-items: center center;
    font-size: 1.1rem;
}

.nailArtMessage{
    margin-top: 15px;
    grid-row: 2 / 3;
    grid-column: 1 / 3;
    font-weight: bold;
    font-size: 1.25rem;
}

.description {
    margin-top: 2.5vh;
}

.floatButton {
    position: sticky;
    top: 12.75vh;
    padding: 1vh;
    width: 95%;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
    .SingleSalonTreatment {
        grid-template-columns: 1fr 1fr;
    }

    .statsContainer {
        grid-column: 2 / 3;
    }
    .treatmentImage {
        position: sticky;
        grid-column: 1 / 2;
        grid-row: 3 / 7;
        width: 50vh;
        height: 50vh;
    }
    .title {
        grid-column: 2 / 3;
    }
    .description {
        grid-column: 2 / 3;
    }
}

@media only screen and (min-width : 321px) and (max-width : 1023px) and (orientation : landscape) {
    .SingleSalonTreatment {
        grid-template-columns: 1fr 1fr;
    }

    .statsContainer {
        grid-column: 2 / 3;
    }

    .treatmentImage {
        position: sticky;
        grid-column: 1 / 2;
        grid-row: 3 / 7;
        width: 50vh;
        height: 50vh;
    }
    .title {
        grid-column: 2 / 3;
    }
    .description {
        grid-column: 2 / 3;
    }
}