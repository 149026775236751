.Reviews {
    margin-top: 5vh;
}

.ReviewsContainer, .Review, .fbHeader, .fbStars {
    display: grid;
    place-items: center center;
}

.ReviewsContainer {
    grid-template-columns: 0.5fr 9fr 0.5fr;
}

.notSelectable {
    position: relative;
    color: lightgray;
}

.ReviewsContainer svg {
    font-size: 1.5rem;
}

.Review {
    background: whitesmoke;
    border-radius: 5px;
    padding: 2.5px;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    grid-template-rows: 1fr 9fr;
}

.Review::-webkit-scrollbar {
    display: none;
  }

.fbHeader, .fbStars {
    width: 100%;
    grid-auto-flow: column;
}

.fbStars {
    color: goldenrod;
    width: 85%;
}

.fbHeader img {
    border-radius: 25px;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
    .Reviews {
        font-size: 1.5rem;
    }

    .Review {
        margin: 10px;
        height: 100%;
    }
    .largeScreenReviewsContainer {
        height: 53vh;
        display: grid;
        place-items: center center;
        grid-auto-flow: column;
    }
}

@media only screen and (min-width : 321px) and (max-width : 1023px) and (orientation : landscape) {
    
    .Reviews {
        font-size: 1.5rem;
    }
    
    .Review {
        margin: 2.5vh 0;
        height: 55vh;
        border-radius: 5px;
    }
}

@media only screen and (min-width : 2000px) and (max-width : 2500px) and (orientation : landscape) {
    
    .Reviews {
        font-size: 1.5rem;
    }
    
    .largeScreenReviewsContainer {
        height: 25vh;
        display: grid;
        place-items: center center;
        grid-auto-flow: column;
    }
    
    .Review {
        margin: 0;
        height: 20vh;
        grid-template-rows: 1fr 9fr;
    }
}