.LandingPage, .imageContainer, .imageNav, .notSelectable {
    display: grid;
    place-items: center center;
}

.LandingPage {
    grid-area: main;
    width: 100%;
    height: 100%;
}

.imageContainer {
    height: 72.5vh;
    width: 100%;
    grid-auto-flow: column;
    position: relative;
}

.imageNav, .notSelectable {
    font-size: 1.5rem;
    position: absolute;
    z-index: 1;
    height: 25%;
    background: var(--second-bg-trans);
    width: 10%;
    border-radius: 2.5px;
}

.notSelectable {
    display: none;
}

.next {
    right: 1%;
}

.prev {
   left: 1%;
}

.landingPageImage { 
    width: 100%;
    height: 72.5vh;
}

.notSelectable {
    color: lightgray;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
    .imageContainer {
        height: 80vh;
    }

    .landingPageImage { 
        height: 80vh;
    }

    .imageNav, .notSelectable {
        width: 5%;
    }
}

@media only screen and (min-width : 321px) and (max-width : 1023px) and (orientation : landscape) {
    .imageContainer {
        height: 87.5vh;
    }
    
    .LandingPage {
        grid-area: main;
    }

    .landingPageImage { 
        height: 87.5vh;
    }

    .imageNav, .notSelectable {
        width: 7.5%;
    }

    .next {
        right: 5%;
    }
    
    .prev {
       left: 5%;
    }
}