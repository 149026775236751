.basketItem, .quantityContainer, .basketItemImage {
    display: grid;
    place-items: center center;
    width: 100%;
}

.basketItem, .basketItemLayoutVoucher {
    text-align: left;
    grid: 1fr 1fr / 1.5fr 5.5fr 1fr 1fr 1fr;
    font-size: 1rem;
    padding-left: 5%;
    margin-top: 1.5vh;
    height: 8.5vh;
}

.basketItemLayoutVoucher {
    grid: 1fr 1fr / 1.5fr 5.5fr 1fr 1fr 1fr;
}

.basketItemTitleContainer, .basketItemTitleContainerVoucher {
    place-self: center start;
    font-size: 0.85rem;
    grid-row: 1 / 3;
    grid-column: 2 / 3;
    padding-left: 2.5px;
}

.basketItemTitleContainerVoucher {
    grid-column: 1 / 3;
}

.quantityContainer {
    grid-auto-flow: column;
}

.basketItem svg {
    font-size: 1.25rem;
}

.basketItemImage {
    height: 100%;
    grid-row: 1 / 3;
}

.basketItemImage img {
    height: 80%;
    width: 100%;
}

.price, .priceVoucher {
    grid-row: 1 / 2;
    grid-column: 3 / 6;
}

.quantityContainer {
    grid-row: 2 / 3;
    grid-column: 3 / 5;
}

.delete, .deleteVoucher {
    grid-row: 2 / 3;
    grid-column: 5 / 6;
}

.deleteVoucher, .priceVoucher {
    grid-row: 1 / 3;
}

.priceVoucher {
    grid-column: 3 / 5;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
    .basketItem {
        height: 7.5vh;
        margin-top: 2.5vh;
        font-size: 1.25rem;
        grid: 1fr 1fr / 1fr 5.5fr 1fr 1.5fr 1fr;
    }

    .basketItemImage img {
        height: 7.5vh;
    }

    .basketItemTitleContainer {
        font-size: 1.5rem;
        padding-left: 25px;
    }

    .basketItem svg {
        font-size: 1.5rem;
    }

    .price {
        grid-row: 1 / 3;
        grid-column: 3 / 4;
    }
    
    .quantityContainer {
        grid-row: 1 / 3;
        grid-column: 4 / 5;
    }
    
    .delete {
        grid-row: 1 / 3;
        grid-column: 5 / 6;
    }
}

@media only screen and (min-width : 321px) and (max-width : 1023px) and (orientation : landscape) {
    .basketItem {
        height: 15vh;
        margin-top: 2.5vh;
    }

    .basketItemImage img {
        height: 15vh;
    }
}