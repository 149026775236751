.BasketMainControlsContainer {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center center;
}

.BasketMainControls, .bookTreatmentsButton {
    display: grid;
    place-items: center center;
}
.BasketMainControls {
    height: 100%;
    width: 100%;
    grid-auto-flow: column;
}

.bookTreatmentsButton {
    font-size: 0.9rem;
    padding: 0 25px;
    height: 65%;
    margin: 0;
    min-width: 75%;
    width: auto;
}