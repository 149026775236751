.AdminLandingPage, .topSellersContainer, .topSeller, .allStatsContainer, .newServicesContainer, .newServiceWithSubContainer, .newService, .newSubService {
    display: grid;
    place-items: center center;
}

.AdminLandingPage {
    grid-area: header/latest/main;
    padding: 1vh 0 2.5vh 0;
    width: 100%;
    background: var(--main-bg);
    z-index: 4;
}

.adminTitle {
    display: grid;
    place-items: center center;
    grid-auto-flow: column;
    width: 100%;
}

.topSellersContainer {
    width: 100%;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
}

.topSeller {
    position: relative;
    border: var(--amber-txt) solid 2px;
    height: 95%;
    width: 95%;
    font-size: 100%;
    border-radius: 2.5px;
    padding: 1.5px;
}

.topSeller svg{
    font-size: 2rem;
    position: absolute;
    top: -20px;
    left: -5px;
    color: goldenrod;
}

.allStatsContainer {
    width: 95%;
    grid: 20vh 20vh / 1fr 1fr 1fr 1fr 1fr 1fr;
}

.singleStatContainer {
    height: 95%;
    width: 95%;
    background: var(--second-bg);
    border-radius: 2.5px;
    display: grid;
    place-items: start center;
    grid-template-rows: 6fr 4fr;
    grid-column: span 2;
}

.statNumber {
    font-size: 2rem;
    background: var(--main-bg);
    width: 50%;
    border-radius: 25px;
}

.newServicesContainer {
    width: 95%;
}

.newServiceWithSubContainer {
    width: 100%;
}

.newService, .newSubService {
    width: 75%;
    background: var(--main-txt);
    color: var(--second-bg);
    border-radius: 2.5px;
    font-size: 100%;
    grid-auto-flow: column;
    margin-bottom: 2vh;
    padding: 2.5px 0;
}

.newSubService { 
    color: var(--main-txt);
    background: var(--second-bg);
    width: 65%;
    place-self: center end;
    margin-right: 12.5%;
}

.newService svg, .newSubService svg {
    font-size: 175%;
}

.changePassword {
    background: var(--amber-txt);
    color: var(--error-txt);
    width: 50%;
    padding: 2.5px 0;
    font-size: 1rem;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
    .AdminLandingPage {
        grid-area: header/main;
        grid: 1fr 1fr / 8fr 2fr;
    }

    .adminTitle {
        display: none;
    }

    .allStatsContainer {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        grid: 25vh / 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    .topSellersContainer{
        grid-row: 1 / 3;
        height: 100%;
        grid-auto-flow: row;
        grid-template-columns: 1fr;
    }
    
    .topSeller {
        font-size: 1.5rem;
        border-radius: 2.5px;
    }

    .topSeller svg{
        top: -15px;
        left: -28px;
    }

    .singleStatContainer {
        grid-column: span 1;
    }

    .newServicesContainer {
        grid-template-columns: 1fr 1fr 1fr;
        place-items: start center;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    .newService, .newSubService {
        width: 60%;
        font-size: 1.15vw;
    }

    .newService:hover {
        background: var(--second-bg);
        color: var(--main-txt);
    }

    .newSubService:hover {
        background: var(--main-txt);
        color: var(--second-bg);
    }

    .changePassword {
        box-shadow: none;
        font-size: 75%;
    }

    .changePassword:hover {
        background: var(--error-txt);
        color: var(--main-bg);
    }
}

@media only screen and (min-width : 321px) and (max-width : 1023px) and (orientation : landscape) {

    .AdminLandingPage {
        grid-area: main;
        z-index: 2;
    }

    .adminTitle {
        display: none;
    }

    .topSellersContainer{
        width: 50%;
    }

    .topSeller {
        padding: 5vh 0;
    }

    .topSeller svg{
        top: -7.5px;
        left: 0;
    }

    .changePassword {
        width: 25%;
    }
    .newService, .newSubService {
        width: 55%;
        height: auto;
        padding: 7.5px 0;
    }

    .newSubService {
        margin-right: 15%;
    }

    .singleStatContainer {
        grid-column: span 1;
    }

    .singleStatContainer {
        grid-column: span 2;
    }

    .allStatsContainer {
        width: 75%;
        grid: 30vh 30vh / 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    .newServicesContainer {
        width: 75%;
    }

    .singleStatContainer {
        height: 95%;
        width: 95%;
        background: var(--second-bg);
        border-radius: 2.5px;
        display: grid;
        place-items: center center;
        grid-template-rows: 3fr 7fr;
        grid-column: span 2;
    }
}

@media only screen and (min-width : 1024px) and (orientation : portrait) {
    .topSellersContainer {
        width: 75%;
        padding: 15px 0;
    }

    .topSeller{
        padding: 10px;
    }
}