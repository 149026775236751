.loadingContainer, .componentContainer {
    top: 35%;
    left: 25%;
    height: 30%;
    width: 50%;
    position: fixed;
    z-index: 10;
    display: grid;
    place-items: end center;
}

.componentContained {
    position: static;
    z-index: 0;
}

.loading, .component {
    background: var(--main-bg);
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center center;
    z-index: 11;
    border-radius: 3.75px;
}

.componentContainer {
    position: static;
    z-index: 10;
    grid-column: 1 / -1;
}