.map {
  width: 90%;
}
.map-h2 {
    text-transform: uppercase;
    font-size: 1rem;
    padding: 20px;
    padding-left: 10px;
    text-align: center;
  }
  
  .googleMap {
    margin-top: 25px;
    width: 100%;
    height: 40vh;
  }
  
  .pin {
    display: flex;
    align-items: center;
    width: 180px;
    color: var(--main-blue);
  }
  
  .pin svg {
    font-size: 2.5rem;
  }
  
  .pinText {
    font-size: 1.5em;
  }

  .contact {
    margin-top: 2.5px;
  }
