.confirmDeleteContainer {
    top: 35%;
    left: 10%;
    height: 30%;
    width: 80%;
    position: fixed;
    z-index: 10;
    display: grid;
    place-items: end center;
}

.ConfirmDelete, .choice {
    width: 100%;
    display: grid;
    place-items: center center;
}

.ConfirmDelete {
    background: var(--main-bg);
    height: 100%;
    z-index: 11;
    border-radius: 3.75px;
}

.choice {
    grid-auto-flow: column;
}

.yes, .no, .approve {
    border: none;
    width: 80%;
    color: var(--main-bg);
}

.yes {
    background: var(--error-txt);
}

.approve {
    background: var(--scs-bg);
}

.Logo {
    width: 25%;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
    .confirmDeleteContainer {
        left: 25%;
        width: 50%;
    }
}