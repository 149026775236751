.AddCurriculum, .addedCurriculumItemContainer, .newCurriculumItemContainer, .newCurriculumItem, .addedCurriculumItemNumber, .addedCurriculumItemRemove {
    display: grid;
    place-items: center center;
}

.AddCurriculum {
    grid-area: main;
    padding: 1vh 0 2.5vh 0;
    height: 100%;
    width: 100%;
    background: var(--main-bg);
    z-index: 2;
    grid-template-columns: 1fr;
}

.addedCurriculumItemContainer {
    width: 97.5%;
    grid-template-columns: 7fr 2fr 1fr;
    font-size: 1.5rem;
    height: minmax(7.5vh, auto);
    margin-top: 1.5vh;
    background: white;
    border-radius: 5px;
    position: relative;
}

.numberOfTreatments {
    position: absolute;
    top: -20px;
    left: -10px;
    font-size: 2rem;
    color: var(--yes-txt)
}

.addedCurriculumItemContainer input {
    margin: 0;
}

.addedCurriculumItemContainer input {
    width: 100%;
    box-shadow: none;
    height: 100%;
}

.newCurriculumItemContainer {
    width: 100%;
}

.newCurriculumItemContainer img {
    height: 7.5vh;
    width: 75px;
}

.newCurriculumItem {
    grid-auto-flow: column;
    width: 60%;
    font-size: 1.5rem;
    margin: 15px 0;
}

.addedCurriculumItemNumber, .addedCurriculumItemRemove {
    width: 100%;
    height: 100%;
}

.addedCurriculumItemNumber {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.addedCurriculumItemRemove, .addedCurriculumItemCannotRemove {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    color: var(--error-txt);
}

.addedCurriculumItemCannotRemove {
    color: var(--main-bg);
}

.updateMessageContainer {
    grid-column: 1 / -1;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {

    .AddCurriculum {
        padding: 2.5vh 10vw;
    }

    .newCurriculumItemContainer {
        grid-column: 1 / 3;
        grid-template-columns: 1fr 1fr;
        width: 90%;
    }
    .newCurriculumItem {
        grid-column: 1 / 3;
        width: 40%;
    }
}

@media only screen and (min-width : 321px) and (max-width : 1023px) and (orientation : landscape) {

    .addedCurriculumItemContainer {
        height: 10vh;
        margin-top: 5vh;
    }

    .AddCurriculum {
        padding: 2.5vh 10vw;
    }

    .newCurriculumItemContainer img {
        height: 9.5vh;
    }

    .newCurriculumItemContainer {
        grid-column: 1 / 3;
        grid-template-columns: 1fr 1fr;
        width: 90%;
    }
    .newCurriculumItem {
        grid-column: 1 / 3;
        width: 40%;
    }
}