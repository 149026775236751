.Layout {
    width: 100%;
    height: 100%;
    display: grid;
    grid:
        'toolbar toolbar toolbar toolbar toolbar toolbar' 7.5vh
        'header header header header header header' 15vh
        'latest latest latest latest latest latest' 5vh
        'main main main main main main' minmax(72.5vh, auto)
        'footer footer footer footer footer footer' minmax(20vh, auto)
        / 1fr 1fr 1fr 1fr 1fr 1fr
        ;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
    .Layout {
        grid:
            'header toolbar toolbar toolbar toolbar toolbar' 15vh
            'header latest latest latest latest latest' 5vh
            'main main main main main main' minmax(60vh, auto)
            'footer footer footer footer footer footer' minmax(20vh, auto)
            / 1fr 1fr 1fr 1fr 1fr 1fr
        ;
    }
}

@media only screen and (min-width : 321px) and (max-width : 1023px) and (orientation : landscape) {
    .Layout {
        grid:
            'toolbar toolbar toolbar toolbar toolbar toolbar' 12.5vh
            'main main main main main main' minmax(87.5vh, auto)
            'footer footer footer footer footer footer' minmax(20vh, auto)
            / 1fr 1fr 1fr 1fr 1fr 1fr
        ;
    }
}