.NewTrainingCourse {
    grid-area: main;
    padding: 1vh 0 2.5vh 0;
    width: 100%;
    display: grid;
    place-items: center center;
    background: var(--main-bg);
    z-index: 2;
}

@media only screen and (min-width : 321px) and (max-width : 1023px) and (orientation : landscape) {
    .NewTrainingCourse {
        grid-area: main;
    }
}