.GiftVouchers, .voucherNameInputContainer, .voucherExample, .buttonContainer {
    display: grid;
    place-items: center center;
}

.GiftVouchers {
    grid-area: main;
    width: 100%;
    height: 100%;
    grid-template-rows: 0.5fr 8fr 1.5fr;
    padding-top: 1vh;
}

.voucherWizardContainer {
    width: 95%;
}

.voucherValueContainer {
    font-size: 1.5rem;
    position: relative;
    height: 100px;
    display: grid;
    place-items: center center;
    grid-auto-flow: column;
}

  .exampleValue {
      font-size: 3.5rem;
  }

.voucherNameInput {
    margin-top: 2.5vh;
    width: 95%;
}

.voucherExample {
    width: 97.5%;
    height: 100%;
    background: white;
    grid: 4fr 1fr 1fr 3fr 0.5fr 0.5fr/ 1fr 1fr 1fr;
    grid-auto-flow: column;
}

.voucherImage {
    width: 95%;
    height: 95%;
}

.voucherTitle {
    grid-column: 2/4;
    font-size: 1.5rem;
}

.voucherName {
    grid-column: 2/4;
}

.voucherCode {
    grid-column: 1/4;
    font-size: 0.75rem;
}

.voucherMessage {
    grid-column: 1/4;
}

.buttonContainer {
    width: 97.5%;
    grid-auto-flow: column;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
    .GiftVouchers {
        width: 60%;
        margin: 0 20%;
    }
}