.FrontLandingPage, .imageAndControlsContainer, .imageContainer {
    display: grid;
    place-items: center center;
    width: 100%;
}

.FrontLandingPage {
    grid-area: main;
    padding: 1vh 0 2.5vh 0;
    background: var(--main-bg);
    z-index: 2;
}

.imageAndControlsContainer {
    height: 40vh;
    grid-template-columns: 1fr 8fr 1fr;
}

.imageDetilsContainer {
    width: 100%;;
    display: grid;
    place-items: center center;
    grid-auto-flow: column;
}

.imageContainer {
    position: relative;
    height: 100%;
    grid-auto-flow: column;
}

.imageNav, .notSelectable {
    font-size: 1.5rem;
}

.landingPageImage {
    max-height: 40vh;
    max-width: 100%;
}

.notSelectable {
    color: lightgray;
}

.deleteImageButton {
    font-size: 2rem;
    grid-column: auto;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
    .imageAndControlsContainer {
        width: 50%;
    }
}